import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderModule } from './modules/loader/loader.module';
import { LoaderInterceptor } from './modules/loader/loader.interceptor';
import { FileSizePipe } from './pipes/fileSize/file-size.pipe';
import { GenericErrorModule } from './modules/generic-error/generic-error.module';
import { RecaptchaModule } from 'ng-recaptcha-2';
import { AppOperationModeModule } from './modules/app-operation-mode/app-operation-mode.module';
import { HeaderFooterModule } from './modules/header-footer/header-footer.module';
import { ErrorHandlerService } from './services/error-handler.service';
import { LoggingService } from './services/logging.service';

@NgModule({
  declarations: [
    AppComponent,
    FileSizePipe
  ],
    bootstrap: [AppComponent], imports: [BrowserModule,
    AppRoutingModule,
    NgxFileDropModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    LoaderModule,
    GenericErrorModule,
    RecaptchaModule,
    AppOperationModeModule,
        HeaderFooterModule], providers: [
    LoggingService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    provideHttpClient(
      withInterceptorsFromDi(),
      withXsrfConfiguration({ cookieName: 'XSRF-TOKEN', headerName: 'X-XSRF-TOKEN' }) // Example configuration
    )
    ] })
export class AppModule { }
