import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnvironmentGuard } from './guards/environment/environment.guard';
import { GenericErrorComponent } from './modules/generic-error/components/generic-error.component';

const routes: Routes = [
  { path: 'error', component: GenericErrorComponent },
  {
    path: '',
    canActivate: [EnvironmentGuard],
    data: { environments: ['mock', 'dv', 'local', 'sy', 'st', 'production'] },
    loadChildren: () => import('./modules/multi-file-uploader/multi-file-uploader.module').then(m => m.MultiFileUploaderModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
