<section class="wrapper">
  <div class="container pt-4">
    <div class="row">
      <div class="col-md-12">
        <div class="outsidebox">
          <div class="innerbox">
            <div class="d-flex">
              <div class="alerticon">
                <div class="alerticon-bg d-flex">
                  <i class="fa fa-exclamation-triangle mt-1"></i>
                </div>
              </div>
              <div>
                <h2 class="alert-title">
                  Sorry, something went wrong and we’re unable to process your
                  upload.
                </h2>
                <p class="alertmessagetext">
                  Please try again to upload your file(s). If the problem
                  persists, please contact our Technical Support team at
                  <a href="tell:1.888.855.2477">1.888.855.2477</a>, Monday
                  through Friday, 7 a.m. to 5 p.m. PT.
                </p>
                <a
                  class="btn-md btn-blue btn mt-3"
                  href="javascript:void(0)"
                  (click)="tryAgain()"
                  >Try again</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
