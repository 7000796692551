import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  // converts fileSize to kb, mb
  transform(value: number, ...args: unknown[]): unknown {
    if (value >= (1024 * 1024)) { // starting from 1 MB
      return `${+parseFloat((value / (1024 * 1024)).toString()).toFixed(1)} MB`;
    } else if ((value >= 1024)) {
      return `${+parseFloat((value / 1024).toString()).toFixed(0)} KB`
    }
    else {
      return value;
    }
  }

}
