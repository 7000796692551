import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppOperationModeService } from './services/app-operation-mode.service';
import { AppOperationModeComponent } from './components/app-operation-mode.component';

// This modules overwrites application's default operaion mode to other modes such as : qa, mock, etc
// this is done by passing differnet modes in the query parameters of the url: ?QA=true



@NgModule({
  declarations: [
    AppOperationModeComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [AppOperationModeComponent],
  providers: [AppOperationModeService]
})
export class AppOperationModeModule { }
