import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericErrorComponent } from './components/generic-error.component';
import { Router } from '@angular/router';



@NgModule({
  declarations: [
    GenericErrorComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [GenericErrorComponent]
})
export class GenericErrorModule { }
