import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppOperationModeService } from '../services/app-operation-mode.service';

@Component({
  selector: 'app-operation-mode',
  templateUrl: './app-operation-mode.component.html',
  styleUrls: ['./app-operation-mode.component.scss']
})
export class AppOperationModeComponent implements OnInit {
  operationFlagsArr$: Observable<string[]>;
  showBanner = false;
  constructor(private appOperationModeService: AppOperationModeService) {
    // the following pipe will take the operatinalFlags from appOperationModeService as an object, kees only tru vlaues and return as an array of strings:
    this.operationFlagsArr$ = appOperationModeService.operatinalFlags$
      .pipe(
        map(flags => flags || {}),
        map(flags => Object.keys(flags).filter(flagKey => flags[flagKey])),
        tap(() => setTimeout(() => this.showBanner = true, 0))
      );
  }

  ngOnInit(): void {
  }

  disableOperationMode() {
    this.showBanner = false;
  }
  // this is called when the trransition effect on the element is completed:
  transitionEnd() {
    if (!this.showBanner) {
      this.appOperationModeService.resetAllModes();
    }
  }

}
