import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // dont show loader for requests with SkipLoader header
    if (req.headers.get('SkipLoader')) {
      const newHeaders = req.headers.delete('SkipLoader');
      const newRequest = req.clone({ headers: newHeaders });
      return next.handle(newRequest);
    } else {
      return next.handle(req)
        .pipe(
          finalize(() => this.loaderService.stopLoader())
        )
    }
  }
}
