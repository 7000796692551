<ng-container *ngIf="operationFlagsArr$ | async as operationFlagsArr">
  <div
    *ngIf="operationFlagsArr.length"
    class="mocks p-1"
    [class.slide-out]="!showBanner"
    [class.slide-in]="showBanner"
    (transitionend)="transitionEnd()"
  >
    <span
      *ngFor="let flag of operationFlagsArr"
      class="badge badge-info badge-leafy mr-1"
      >{{ flag.slice(0, 2) | uppercase }}</span
    >Click to
    <a href="javascript:void(0)" (click)="disableOperationMode()">disable</a>
  </div>
</ng-container>
