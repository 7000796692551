import { Component, HostListener } from '@angular/core';
import { LoggingService } from './services/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Upload claim documents';
  constructor(private loggingService: LoggingService) {
  }

  ngOnInit() {
    this.loggingService.logEvent('web-upload-applicationOpened');
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.loggingService.logEvent('web-upload-applicationClosed');
  }
}