import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit {
  constructor(private location: Location) { }
  ngOnInit(): void {
  }

  tryAgain() {
    this.location.back()
  }

}
