import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';

/**
 * This guard can protect any route from getting accessed if not listed in the data.environments for that route
 */

@Injectable({
  providedIn: 'root'
})
export class EnvironmentGuard  {
  constructor(private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const env = environment.name;
    const canActivate = route.data.environments.some((item: string) => item === env);
    if (!canActivate) { this.router.navigate(['/']); }
    return canActivate;
  }
}
